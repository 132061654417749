import './firebase/firebase'
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { TranslationProvider } from "react-admin";
import { Router } from "react-router-dom";
import { AdminAuthProvider } from "./firebase/context";
import store, { history } from "./firebase/store";
import * as serviceWorker from "./serviceWorker";
import Splash from "./components/Splash";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ErrorBoundary } from "./components/Error/Boundary";
import { SnackbarProvider } from "notistack";
import { InvertableThemeProvider } from "./theme";
import App from "./App";
import "./index.css";
import { GeneratedImagesProvider } from './routes/Usernames/hooks/useGenerateImage';

const reload = () => {
  window.location.reload(true)
}

ReactDOM.render(
  <InvertableThemeProvider>
    <Suspense fallback={<Splash>Loading...</Splash>}>
      <ErrorBoundary
        fallback={(error) => (
          <Splash>
            <div>
              {error.message}{" "}
              <button className="btn btn-outline-light ml-2" onClick={reload}>
                Reload
              </button>
            </div>
          </Splash>
        )}
      >
        <Provider store={store}>
          <SnackbarProvider>
            <TranslationProvider>
              <Router history={history}>
                <AdminAuthProvider>
                  <GeneratedImagesProvider>
                    <CssBaseline />
                    <App />
                  </GeneratedImagesProvider>
                </AdminAuthProvider>
              </Router>
            </TranslationProvider>
          </SnackbarProvider>
        </Provider>
      </ErrorBoundary>
    </Suspense>
  </InvertableThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
