import { useFirebaseUserCredentials } from "../firebase/context";

export const RequireCredentials = ({ required, some, children }) => {
  const credentials = useFirebaseUserCredentials()
  if (credentials && some && some.some((resource) => credentials[resource])) {
    return typeof children === 'function' ? children() : children
  }
  if (credentials && required && required.every((resource) => credentials[resource])) {
    return typeof children === 'function' ? children() : children
  }
  return null
}
