import React from "react";
import { setContext } from "apollo-link-context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
} from "@apollo/client";
import { getAuth } from "firebase/auth";

const authLink = setContext(async (op, { headers }) => {
  const user = getAuth().currentUser;
  const token = user ? await user.getIdToken() : "";
  return {
    headers: {
      ...headers,
      "X-Auth-Token": token,
    },
  };
});

export const getClient = (uri) =>
  new ApolloClient({
    link: concat(authLink, new HttpLink({ uri })),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    }
  });

export const makeApolloAuthProvider = (uri) => {
  const client = getClient(uri);
  return ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  };
};
