import React, { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // trackWarning('Error Boundary', error, info)
    if (/Loading chunk (.*) failed./.test(error.message)) {
      this.setState({
        error: {
          message: "New version found, please reload.",
        },
      });
    } else {
      this.setState({ error });
    }
  }

  render() {
    const { fallback, children } = this.props;
    const { error } = this.state;
    if (error) return fallback(error);
    return children;
  }
}
