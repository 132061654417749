import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AuthStatus from '../../auth/Status';
// import Notifications from '../Notifications';
import { NavGlobalSearch } from '../GlobalSearch';
import CoinLogo from '../Logos/Coin';
import cx from 'classnames'
import Nav2 from './mui'

export const NavLegacy = ({ navLinks }) => {
  const [show, setMenuOpen] = useState(false)
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light justify-content-start border-bottom' style={{ zIndex: 1201 }}>
      <Link className='navbar-brand' to='/'><CoinLogo className='mr-2' style={{ height: '2em' }} /> Admin</Link>
      <div className='flex-grow-1 d-lg-none' />
      <div className='d-lg-none'>
        <AuthStatus />
        {/* <Notifications /> */}
      </div>
      <button className="navbar-toggler ml-1" type="button" onClick={() => setMenuOpen(!show)}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={cx('collapse navbar-collapse', { show })}>
        <div className='navbar-nav mr-auto'>
          {navLinks}
        </div>
        <NavGlobalSearch />
      </div>
      <div className='d-none d-lg-block'>
        <AuthStatus />
        {/* <Notifications /> */}
      </div>
    </nav>
  )
}

export default Nav2