import React, { createContext, useContext, useState, useEffect } from 'react'
import { configureRollbar } from '../utils/rollbar'
import { httpsCallable } from 'firebase/functions'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

export const db = getFirestore()
export const firebaseAuthContext = createContext(getAuth())
export const firebaseDbContext = createContext(db)
export const firebaseUserContext = createContext({ initialized: false, user: null, credentials: null })
export const coinAdminFunction = httpsCallable('coin_admin_1_0')

export const useFirebaseAuth = () => {
  return useContext(firebaseAuthContext)
}

export const useFirebaseInitializeUser = () => {
  return useContext(firebaseUserContext).initialized
}

export const useFirebaseUser = () => {
  return useContext(firebaseUserContext).user
}

export const useFirebaseUserCredentials = () => {
  return useContext(firebaseUserContext).credentials
}

export const useCoinAdminFunction = () => {
  return coinAdminFunction
}

export const useFirebaseCollection = (collectionPath) => {
  return {
    doc: (...pathSegments) => doc(db, collectionPath, ...pathSegments)
  }
}

export const AdminAuthProvider = ({ children }) => {
  const auth = useFirebaseAuth()
  const collection = useFirebaseCollection('coin_admins')
  const [value, setValue] = useState({ initialized: false, user: auth.currentUser, credentials: null })

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        setValue({ user })
      } else {
        setValue({ user, initialized: true })
      }
    }, (err) => {
      console.log('User error', err)
      setValue({ user: null, initialized: true })
    })
  }, [auth])

  useEffect(() => {
    if (!value.user) return
    configureRollbar(value.user)

    return onSnapshot(collection.doc(value.user.uid), (doc) => {
      const credentials = doc.data()
      setValue(v => ({ ...v, initialized: true, credentials }))
    }, (err) => {
      console.log('Credentials error', err)
      setValue(v => ({ ...v, initialized: true, credentials: null }))
    })
  }, [value.user])

  return (
    <firebaseUserContext.Provider value={value}>
      { children }
    </firebaseUserContext.Provider>  
  )
}