import { getAuth } from 'firebase/auth'

export const getRequestContext = async () => {
  const user = getAuth().currentUser
  if (!user) return {}
  return {
    headers: {
      'X-Stage': 'live',
      'X-Auth-Token': await user.getIdToken(),
    }
  }
}

export const getVerifyRequestContext = async () => {
  const user = getAuth().currentUser
  if (!user) return {}
  return {
    headers: {
      'X-Auth-Token': await user.getIdToken(),
    }
  }
}
