import React, { FC, useState, useEffect, HTMLProps } from 'react'
import useCurrentId, { isBeaconId, isMaphash } from './useCurrentId'
import { TextField, Button, FormControl } from '@material-ui/core'

export const useGlobalSearch = () => {
  const [{ currentId, loading }, setCurrentId] = useCurrentId()
  const [value, setValue] = useState(currentId)
  const onChange = (ev) => {
    setValue(ev.target.value)
  }
  const onSubmit = (ev) => {
    ev.preventDefault()
    setCurrentId(value)
  }
  useEffect(() => {
    setValue(currentId)
  }, [currentId])
  return [{ value, loading }, { onChange, onSubmit }]
}

export const NavGlobalSearch = () => {
  const [{ value, loading }, { onChange, onSubmit }] = useGlobalSearch()

  return (
    <form onSubmit={onSubmit} disabled={loading} style={{ display: 'flex', flexWrap: 'nowrap' }}>
      <TextField
        value={value || ''}
        onChange={onChange}
        name="search"
        type="search"
        placeholder="[email | user id | username | beacon id | ETH]"
        style={{ maxWidth: '100%' }}
        className="nav-search"
        variant="outlined"
        margin='none'
        size='small'
      />
      <FormControl margin='none' style={{ marginLeft: 4, marginTop: 2 }}>
        <Button type="submit" disabled={loading}>Search</Button>
      </FormControl>
    </form>
  )
}

export default () => {
  const [{ value, loading }, { onChange, onSubmit }] = useGlobalSearch()
  return (
    <form className="my-2 my-lg-0" onSubmit={onSubmit} disabled={loading}>
      <TextField 
        value={value || ''}
        onChange={onChange}
        name="search"
        type="search"
        style={{ maxWidth: '100%' }}
        className="nav-search"
        variant="outlined"
        helperText="Search by Email or User ID"
      />
      <div className='text-right'>
        <Button variant="outlined" type="submit" disabled={loading}>Search</Button> 
      </div>
    </form>
  )
}

const SearchingButton = ({ loading, disabled, ...props }) => {
  return <button disabled={disabled || loading} {...props}>
    {loading ? <span className='spinner-border spinner-border-sm mr-1' /> : <></> }
    {loading ? 'Searching...' : 'Search'}
  </button>
}