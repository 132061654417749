import React, { useState } from "react";
import { Box, Drawer, AppBar as MuiAppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Menu, Close } from "@material-ui/icons";
import { Link } from "react-router-dom";
import CoinLogo from "../Logos/Coin";
import AuthStatus from "../../auth/Status";
import { NavGlobalSearch } from "../GlobalSearch";
import { InvertableThemeButton } from "../Buttons";

const key = 'menu-open'
const initialValue = localStorage.getItem(key) !== 'false'
const drawerWidth = 240;

const AppBar = ({ open, children, ...rest }) => {
  const theme = useTheme()
  const style = {
    // transition: theme.transitions.create(["margin", "width"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      // transition: theme.transitions.create(["margin", "width"], {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    })
  }
  return <MuiAppBar {...rest} style={style}>
    {children}
  </MuiAppBar>
}

const Main = ({ open, children }) => {
  const theme = useTheme()
  const style = {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: '100vw',
    marginLeft: -drawerWidth,
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    ...(open && {
      // transition: theme.transitions.create("margin", {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
      maxWidth: `calc(100vw - ${drawerWidth}px)`,
      marginLeft: 0,
    })
  }
  return <Box style={style}>
    {children}
  </Box>
}

export default ({ navLinks, children }) => {
  const [open, setOpen] = useState(initialValue);
  const handleToggle = () => {
    setOpen(!open)
    localStorage.setItem(key, String(!open))
  }
  return (
    <Box style={{ display: "flex", flexGrow: 1 }}>
      <AppBar position="fixed" open={open} color="default">
        <Toolbar>
          {!open ? <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleToggle}
            edge="start"
            style={{ marginRight: 2 }}
          >
            <Menu />
          </IconButton> : null}
          <Box style={{ flex: 1 }} />
          <NavGlobalSearch />
          <Box style={{ flex: 1 }} />
          <Box px={1}>
            <InvertableThemeButton />
          </Box>
          <AuthStatus />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        style={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        PaperProps={{
          style: { width: drawerWidth },
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link
          to="/"
          style={{
            color: "inherit",
            display: "flex",
            alignItems: "center",
            padding: 8,
          }}
        >
          <CoinLogo className="mr-2" style={{ height: "2em" }} /> <Typography variant="h5">Admin</Typography>
        </Link>
        {open ? <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleToggle}
            edge="start"
            style={{ marginRight: 2 }}
          >
            <Close />
          </IconButton> : null}
        </Box>
        {navLinks}
      </Drawer>
      <Main open={open}>
        <Toolbar />
        {children}
      </Main>
    </Box>
  );
};
