import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";
import { apiUri } from "./api";
import { IProfileImage } from "../types";
import { getAuth } from "firebase/auth";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { IGenerateImageOptions } from "./image";

export function useGenerateImage(
  options?: SWRMutationConfiguration<IProfileImage | null, unknown>
) {
  return useSWRMutation(
    `generate/image`,
    async (_: string, { arg }: { arg?: IGenerateImageOptions }) => {
      const result = await generateImage(arg);
      return result;
    },
    options
  );
}

async function generateImage(
  options?: IGenerateImageOptions
): Promise<IProfileImage | null> {
  const user = getAuth().currentUser;
  if (!user) throw new Error("Unauthenticated");
  const token = await user.getIdToken();
  const res = await fetch(`${apiUri}/generate/image`, {
    method: "POST",
    body: JSON.stringify(options),
    headers: { "Content-Type": "application/json", "X-Auth-Token": token },
  });
  const data = await res.json();

  if (res.status !== 200) {
    throw new Error(data.message);
  }

  return data;
}

export type IGeneratedImages = Record<string, string[]>

export type IGeneratedImagesContext = [IGeneratedImages, React.Dispatch<React.SetStateAction<IGeneratedImages>>]

const GeneratedImagesContext = createContext<IGeneratedImagesContext | null>(null)

export const GeneratedImagesProvider = ({ children }: PropsWithChildren<{}>) => {
  const [generatedImages, setGeneratedImages] = useState<IGeneratedImages>({})

  return <GeneratedImagesContext.Provider value={[generatedImages, setGeneratedImages]}>{children}</GeneratedImagesContext.Provider>
}

export const useGeneratedImages = () => {
  const ctx = useContext(GeneratedImagesContext)

  if (!ctx) throw new Error('GeneratedImagesProvider not found in root')

  return ctx
}

export const useGeneratedImagesValue = () => {
  return useGeneratedImages()[0]
}

export const useGeneratedImagesSetValue = () => {
  return useGeneratedImages()[1]
}