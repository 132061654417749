import React from 'react'
import Web3 from 'web3'
import standardErc20Abi from './standardErc20'
import multisender from './multisender'
import { createContext, useContext, useState, useEffect } from 'react'

const multisenderContractAddress = '0xA5025FABA6E70B84F74e9b1113e5F7F4E7f4859f'
const xyoContractAddress = '0x55296f69f40ea6d20e478533c15a6b08b654e758'

let web3InstancePromise, web3Instance, web3Error;
export const web3Context = createContext();

export const Web3Provider = ({ children }) => {
    const [web3, setWeb3] = useState()
    useEffect(() => {
        if (window.ethereum) {
            const _web3 = new Web3(window.ethereum);
            window.ethereum.enable()
            .then(() => {
                setWeb3(_web3)
            })
            .catch((e) => {
                console.log(e)
            })
        } else if (window.web3) {
            const _web3 = new Web3(window.web3.currentProvider);
            setWeb3(_web3)
        } else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }, [])
    return (
        <web3Context.Provider value={web3}>
            {children}
        </web3Context.Provider>
    )
}

export const initializeWeb3 = async () => {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable()
        return web3
    } else if (window.web3) {
        return new Web3(window.web3.currentProvider);
    } else {
        throw new Error('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
}

export const useWeb3 = () => {
    if (web3Error) return null
    if (web3Instance) return web3Instance
    if (web3InstancePromise) throw web3InstancePromise
    web3InstancePromise = initializeWeb3()
    .then((web3) => {
        web3Instance = web3
    })
    .catch(e => {
        web3Error = e.message
    })
    throw web3InstancePromise
}

export const useErc20Contract = (...args) => {
    const web3 = useWeb3()
    if (web3) {
        const contract = new web3.eth.Contract(standardErc20Abi, ...args)
        return contract
    }
}

export const useWeb3Account = () => {
    const [account, setAccount] = useState(null)
    const web3 = useWeb3()
    useEffect(() => {
        if (web3) {
            web3.eth.getAccounts()
            .then((accounts) => accounts[0])
            .then((account) => setAccount(account))
            .catch(e => console.log(e))
        }
    }, [web3])
    return account
}

export const useContractBalance = (contractAddress) => {
    const account = useWeb3Account()
    const contract = useErc20Contract(contractAddress)
    const [balance, setBalance] = useState(0)
    useEffect(() => {
        if (!account) return
        Promise.all([
            contract.methods.balanceOf(account).call(),
            contract.methods.decimals().call()
        ])
        .then(([{ balance }, decimals]) => fromWei(balance, decimals))
        .then(balance => setBalance(balance))
        .catch(e => console.log(e))
    }, [account])
    return balance
}

export const useTransaction = (txHash) => {
    const [transaction, setTransaction] = useState(null)
    const web3 = useWeb3()
    useEffect(() => {
        if (!txHash || !web3) return
        getTransaction(web3, txHash)
        .then(tx => setTransaction(tx))
        .catch(e => console.log(e))
    }, [web3, txHash])
    return transaction
}

export const useTransactionReceipt = (txHash, ...args) => {
    const [receipt, setReceipt] = useState(null)
    const web3 = useWeb3()
    useEffect(() => {
        if (!txHash || !web3) return
        getReceipt(web3, txHash)
        .then(receipt => setReceipt(receipt))
        .catch(e => console.log(e))
    }, [web3, txHash, ...args])
    return receipt
}

export const getAccounts = async (web3) => {
    const accounts = await web3.eth.getAccounts()
    return accounts
}

export const getErc20Contract = async (web3, contractAddress, from) => {
    const contract = new web3.eth.Contract(standardErc20Abi, contractAddress, { from });
    return contract
}

export const getTransaction = async (web3, txHash) => {
    const tx = await web3.eth.getTransaction(txHash)
    return tx
}

export const getReceipt = async (web3, txHash) => {
    const tx = await web3.eth.getTransactionReceipt(txHash)
    return tx
}

export async function* sendXyo (web3, to, value) {
    const accounts = await getAccounts(web3)
    console.log('accounts', accounts)
    const from = accounts[0]
    const contract = await getErc20Contract(web3, xyoContractAddress, from)
    console.log('contract', contract)
    const { balance } = await contract.methods.balanceOf(from).call();
    console.log('balance', balance, typeof balance)
    const decimals = await contract.methods.decimals().call();
    console.log('decimals', decimals, typeof decimals)
    const computedValue = web3.utils.toWei(String(value))
    console.log(`${value}e${decimals}`, computedValue)
    if (Number(balance) < computedValue) throw new Error('Insufficient funds');

    const transactionReference = contract.methods.transfer(to, computedValue).send({ from })
    const txHash = onTransactionHash(transactionReference)
    const txReceipt = onTransactionReceipt(transactionReference)
    yield txHash
    return txReceipt
}

function onTransactionHash (transactionReference) {
    return new Promise((res, rej) => {
        transactionReference.on('transactionHash', (txHash) => {
            res(txHash)
        })
        transactionReference.on('error', (e) => rej(e))
    })
}

function onTransactionReceipt (transactionReference) {
    return new Promise((res, rej) => {
        transactionReference.on('receipt', (receipt) => {
            res(receipt)
        })
    })
}

export const fromWei = (value, decimals) => Number(`${value}e-${decimals}`)