import React, { useState, useEffect } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { AccountsDocument } from '@xyo-network/api-checkout-apollo'
import { checkoutClient, findAccounts, findRebillyCustomer } from '../../utils/checkout';

export const isOrder = (v) => v.length === 9
export const isBeaconId = (v) => (/\./).test(v || '')
export const isMaphash = (v) => (/^[0123]*$/).test(v || '')
export const isRebilly = (v) => (/cus_|[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/).test(v || '')
export const getPathFromId = (id) => {
  if (isRebilly(id)) return 'customer'
  if (isBeaconId(id)) return 'beacon'
  if (isMaphash(id)) return 'maphash'
  return 'user'
}

export default () => {
  const history = useHistory()
  const match = matchPath(history.location.pathname, {
    path: '/:path/:id',
    exact: true,
  }) || {}
  const { path, id } = match.params || {}
  const [currentId, setCurrentId] = useState('')
  const [loading, setLoading] = useState(false)
  const updateHistory = (id) => {
    if (path === 'profile') return
    if (path === 'username') return
    if (path === 'wordlist') return
    if (path === 'shipstation') return
    if (path === 'inmarket') return
    if (path === 'foursquare-map') return
    if (path === 'foursquare') return
    const { pathname } = history.location
    const newPath = `/${getPathFromId(id)}/${id}`
    if (pathname !== newPath) {
      history.push(newPath)
    }
  }

  useEffect(() => {
    if (path === 'profile') return
    if (path === 'username') return
    if (path === 'wordlist') return
    if (path === 'shipstation') return
    if (path === 'inmarket') return
    if (path === 'foursquare-map') return
    if (path === 'foursquare') return
    setCurrentId(id)
  }, [path])

  useEffect(() => {
    let update = (...args) => updateHistory(...args)
    if (currentId && currentId.trim().match(/^0x[a-fA-F0-9]{40}$/g)) {
      setLoading(true)
      fetch(`https://prod.coinapp.co/verifier`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query addressSearch($address: String!) { addressSearch(address: $address) { uid } }`,
          variables: { address: currentId.trim() }
        })
      })
      .then(res => res.json())
      .then(({ data }) => get(data, 'addressSearch[0].uid'))
      .then(uid => {
        if (!uid) throw new Error('Address not found')
        update(uid)
        setLoading(false)
      })
      .catch(e => {
        alert('Address not found')
        setLoading(false)
      })
    } else if (currentId && !isRebilly(currentId)) {
      setLoading(true)
      checkoutClient.query({ 
        query: findAccounts, 
        variables: { search: currentId.trim() } ,
        errorPolicy: 'ignore'
      })
      .then(({ data }) => data.findAccounts?.[0]?.userInfo?.ownerUID)
      .then((uid) => {
        if (!uid && currentId.indexOf('@') !== '-1') {
          return checkoutClient.query({ query: findRebillyCustomer, variables: { email: currentId.trim() } })
          .then(({ data }) => data && data.customer)
          .then((customer) => {
            if (!customer) throw new Error('Customer not found')
            update(customer.id)
            setLoading(false)
          })
        }
        if (!uid) throw new Error('Not found')
        update(uid)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        alert(e.message)
        setLoading(false)
      })
    }

    return () => {
      update = (...args) => {
        console.warn('Unmounted current id', args)
      }
    }
  }, [currentId])

  return [
    { currentId, loading },
    setCurrentId
  ]
}