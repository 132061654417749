import {
  ShipStationListCarriersDocument,
  ShipStationListOrdersDocument,
  ShipStationListShipmentsDocument,
  ShipStationListFulfillmentsDocument,
  ShipStationListProductsDocument,
  ShipStationListTagsDocument,
  ShipStationListWarehousesDocument,
  ShipStationListStoresDocument,
  ShipStationGetOrderDocument,
  ShipStationCancelOrderDocument,
  ShipStationDeleteOrderDocument,
  ShipStationRestoreOrderDocument,
  ShipStationHoldOrderDocument,
  ShipStationVoidLabelDocument,
  ShipStationMarkOrderAsShippedDocument,
  ShipStationCreateLabelForOrderDocument,
  ShipStationCalculateRatesDocument,
  ShipStationListOrdersByTagDocument,
  SendToSendCloudDocument
} from "@xyo-network/api-checkout-apollo";
import { GET_LIST, GET_MANY } from "react-admin";
import { checkoutClient } from "../utils/checkout";

export const orderStatuses = {
  awaiting_payment: "awaiting_payment",
  awaiting_shipment: "awaiting_shipment",
  pending_fulfillment: "pending_fulfillment",
  shipped: "shipped",
  on_hold: "on_hold",
  cancelled: "cancelled",
};

export const orderSortFields = {
  OrderDate: "OrderDate",
  ModifyDate: "ModifyDate",
  CreateDate: "CreateDate",
};

export const orderSortDir = {
  ASC: "ASC",
  DESC: "DESC",
};

export async function shipStationCarriersProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listCarriers(params);
    case GET_MANY:
      return listCarriers(params);
    default:
      return;
  }
}

export async function shipStationTagsProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listTags(params);
    case GET_MANY:
      return listTags(params);
    default:
      return;
  }
}

export async function shipStationOrdersByTagProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listOrdersByTag(params);
    case GET_MANY:
      return listOrdersByTag(params);
    default:
      return;
  }
}

export async function shipStationOrdersProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listOrders(params);
    case GET_MANY:
      return listOrders(params);
    default:
      return;
  }
}

export async function shipStationShipmentsProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listShipments(params);
    case GET_MANY:
      return listShipments(params);
    default:
      return;
  }
}

export async function shipStationFulfillmentsProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listFulfillments(params);
    case GET_MANY:
      return listFulfillments(params);
    default:
      return;
  }
}

export async function shipStationProductsProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listProducts(params);
    case GET_MANY:
      return listProducts(params);
    default:
      return;
  }
}

export async function shipStationStoresProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return listStores(params);
    case GET_MANY:
      return listStores(params);
    default:
      return;
  }
}

// LIST

export function listCarriers() {
  return checkoutClient
    .query({
      query: ShipStationListCarriersDocument,
    })
    .then(({ data }) => data?.listCarriers);
}

export function listTags() {
  return checkoutClient
    .query({
      query: ShipStationListTagsDocument,
    })
    .then(({ data }) => data?.listTags);
}

export function listWarehouses() {
  return checkoutClient
    .query({
      query: ShipStationListWarehousesDocument,
    })
    .then(({ data }) => data?.listWarehouses);
}

export function listStores() {
  return checkoutClient
    .query({
      query: ShipStationListStoresDocument,
    })
    .then(({ data }) => data?.listStores);
}

export function listOrders({ pagination, filter, sort }) {
  return checkoutClient
    .query({
      query: ShipStationListOrdersDocument,
      variables: { pagination, filter, sort: sortToNum(sort) },
    })
    .then(({ data }) => data?.listOrders);
}

export function listOrdersByTag({ pagination, filter }) {
  return checkoutClient
    .query({
      query: ShipStationListOrdersByTagDocument,
      variables: { pagination, filter },
    })
    .then(({ data }) => data?.listOrdersByTag);
}

export function listShipments({ pagination, filter, sort }) {
  return checkoutClient
    .query({
      query: ShipStationListShipmentsDocument,
      variables: { pagination, filter, sort: sortToNum(sort) },
    })
    .then(({ data }) => data?.listShipments);
}

export function listProducts({ pagination, filter, sort }) {
  return checkoutClient
    .query({
      query: ShipStationListProductsDocument,
      variables: { pagination, filter, sort: sortToNum(sort) },
    })
    .then(({ data }) => data?.listProducts);
}

export function listFulfillments({ pagination, filter, sort }) {
  return checkoutClient
    .query({
      query: ShipStationListFulfillmentsDocument,
      variables: { pagination, filter, sort: sortToNum(sort) },
    })
    .then(({ data }) => data?.listFulfillments);
}

export function getOrder(orderId) {
  return checkoutClient
    .query({
      query: ShipStationGetOrderDocument,
      variables: { orderId },
    })
    .then(({ data }) => data?.getOrder);
}

// MUTATE

export function cancelOrder(orderId) {
  return checkoutClient
    .mutate({
      mutation: ShipStationCancelOrderDocument,
      variables: {
        orderId,
      },
    })
    .then(({ data }) => data?.cancelOrder);
}

export function deleteOrder(orderId) {
  return checkoutClient
    .mutate({
      mutation: ShipStationDeleteOrderDocument,
      variables: {
        orderId,
      },
    })
    .then(({ data }) => data?.deleteOrder);
}

export function holdOrder(orderId, holdUntilDate) {
  return checkoutClient
    .mutate({
      mutation: ShipStationHoldOrderDocument,
      variables: {
        orderId,
        holdUntilDate,
      },
    })
    .then(({ data }) => data?.holdOrder);
}

export function restoreOrderFromHold(orderId) {
  return checkoutClient
    .mutate({
      mutation: ShipStationRestoreOrderDocument,
      variables: {
        orderId,
      },
    })
    .then(({ data }) => data?.holdOrder);
}

export function voidLabel(shipmentId, orderId) {
  return checkoutClient
    .mutate({
      mutation: ShipStationVoidLabelDocument,
      variables: {
        shipmentId,
        orderId,
      },
    })
    .then(({ data }) => data?.voidLabel);
}

export function markOrderAsShipped({
  orderId,
  carrierCode,
  shipDate,
  trackingNumber,
  notifyCustomer,
  notifySalesChannel,
}) {
  return checkoutClient
    .mutate({
      mutation: ShipStationMarkOrderAsShippedDocument,
      variables: {
        input: {
          orderId,
          carrierCode,
          shipDate,
          trackingNumber,
          notifyCustomer,
          notifySalesChannel,
        },
      },
    })
    .then(({ data }) => data?.markOrderAsShipped);
}

export function getRates(input) {
  return checkoutClient
    .mutate({
      mutation: ShipStationCalculateRatesDocument,
      variables: { input },
    })
    .then(({ data }) => data?.calculateRates);
}

export function createLabelForOrder({
  orderId,
  carrierCode,
  serviceCode,
  packageCode,
  confirmation,
  shipDate,
  weight,
  dimensions,
  insuranceOptions,
  internationalOptions,
  advancedOptions,
  testLabel = false,
}) {
  return checkoutClient
    .mutate({
      mutation: ShipStationCreateLabelForOrderDocument,
      variables: {
        input: {
          orderId,
          carrierCode,
          serviceCode,
          packageCode,
          confirmation,
          shipDate: shipDate || new Date().toISOString(),
          weight,
          testLabel
        },
      },
    })
    .then(({ data }) => data?.createLabelForOrder);
}

export function sendToSendCloud (orderId) {
  return checkoutClient.mutate({
    mutation: SendToSendCloudDocument,
    variables: { orderId }
  })
  .then(({ data }) => data?.sendToSendCloud)
}

function sortToNum(sort) {
  if (sort.order === "ASC") return { ...sort, order: 1 };
  return { ...sort, order: -1 };
}
