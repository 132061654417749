import React, { useEffect, useState, useContext, createContext, useRef } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import LinearProgress from '@material-ui/core/LinearProgress'

export { default as AlertTitle } from '@material-ui/lab/AlertTitle'
const defaultValue = {
  message: null,
  action: null,
}

const SnackbarContext = createContext([
  defaultValue,
  {
    setSnackbar: () => console.warn('Snackbar provider not initialized'),
    closeSnackbar: () => console.warn('Snackbar provider not initialized'),
  },
])

export const SnackbarProvider = ({ children }) => {
  const [value, setValue] = useState(defaultValue)
  const ref = useRef(defaultValue)
  const closeSnackbar = () => {
    ref.current = defaultValue
    setValue(defaultValue)
  }
  const setSnackbar = (v) => {
    setValue(v)
    ref.current = v
    return () => {
      if (ref.current === v) {
        setValue(defaultValue)
      }
    }
  }
  return (
    <SnackbarContext.Provider value={[value, { setSnackbar, closeSnackbar }]}>
      {children}
    </SnackbarContext.Provider>
  )
}

export const parseError = (error) => {
  if (!error) return ''
  const message = typeof error === 'string' ? error : error.message
  return message.replace(/Network error: |GraphQL error: |Node error: /g, '')
}

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

export default () => {
  const [snackbar, { setSnackbar, closeSnackbar }] = useSnackbar()
  const [progress, setProgress] = useState(0)
  const intervalId = useRef()
  const open = Boolean(snackbar.message)
  useEffect(() => {
    if (open && snackbar.autoHideDuration) {
      setProgress(0)
      intervalId.current = setInterval(() => {
        setProgress((v) => v + 10)
      }, snackbar.autoHideDuration / 10)
    }
    return () => {
      clearInterval(intervalId.current)
    }
  }, [open, snackbar.autoHideDuration])
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      color="danger"
      autoHideDuration={snackbar.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert
        style={{ position: 'relative' }}
        severity={snackbar.severity}
        onClose={snackbar.loading ? null : closeSnackbar}
      >
        {snackbar.loading ? (
          <LinearProgress
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
          />
        ) : null}
        {snackbar.autoHideDuration ? (
          <LinearProgress
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
            value={progress}
            variant="determinate"
          />
        ) : null}
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}
