export const configureRollbar = (user) => {
  if (!user || !user.uid) return;
  try {
    const code_version = process.env.REACT_APP_GIT_SHA;
    console.log({ code_version, user });
    window.Rollbar.configure({
      payload: {
        person: {
          id: user.uid,
          email: user.email,
        },
        client: {
          javascript: {
            code_version,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};
