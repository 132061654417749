import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'


export const config = {
    apiKey: "AIzaSyCCtXYmkLd3gh6fKjSCGOWhsYoeKccYq-g",
    authDomain: "xyo-network-1522800011804.firebaseapp.com",
    databaseURL: "https://xyo-network-1522800011804.firebaseio.com",
    projectId: "xyo-network-1522800011804",
    storageBucket: "xyo-network-1522800011804.appspot.com",
    messagingSenderId: "542704523793"
};
initializeApp(config);
// export const provider = new firebase.auth.EmailAuthProvider();
export const auth = getAuth();
