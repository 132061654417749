import React, { Fragment } from 'react';
import Routes from './routes';
import Splash from './components/Splash';
import { useFirebaseInitializeUser } from './firebase/context';

export default () => {
  const initialized = useFirebaseInitializeUser()
  if (!initialized) return <Splash>Loading...</Splash>
  return (
    <Fragment>
      <Routes />
    </Fragment>
  )
}