import { useContext, createContext } from 'react'
import { createAdminStore } from 'ra-core'
import { createHashHistory } from 'history';
import defaultMessages from 'ra-language-english';
import dataProvider from './dataProvider'

export const history = createHashHistory()

const store = createAdminStore({
  dataProvider,
  history,
  i18nProvider: () => defaultMessages,
})

export const context = createContext(store)

export const useStore = () => {
  return useContext(context)
}

export default store