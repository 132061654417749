import get from "lodash/get";
import { GET_LIST, GET_MANY } from "react-admin";
import { checkoutClient } from "../utils/checkout";
import {
  shipStationCarriersProvider,
  shipStationOrdersProvider,
  shipStationShipmentsProvider,
  shipStationFulfillmentsProvider,
  shipStationProductsProvider,
  shipStationTagsProvider,
  shipStationStoresProvider,
  shipStationOrdersByTagProvider
} from "./shipstationApi"
import { RefundRequestsDocument, UserInfoPageDocument } from "@xyo-network/api-checkout-apollo";
import {
  coinBossClient,
  redeemHistoryQuery,
  redeemOptionsQuery,
} from "../utils/bossapi";

export default async (type, resource, params) => {
  switch (resource) {
    case "coin_users":
      return coinUsersProvider(type, params);
    case "redeem_history":
      return coinRedeemHistoryProvider(type, params);
    case "refund_history":
      return coinRefundHistoryProvider(type, params);
    case "redeem_options":
      return coinRedeemOptionsProvider(type, params);
    case "ship_station_carriers":
      return shipStationCarriersProvider(type, params);
    case "ship_station_orders":
      return shipStationOrdersProvider(type, params);
    case "ship_station_orders_by_tag":
      return shipStationOrdersByTagProvider(type, params);
    case "ship_station_shipments":
      return shipStationShipmentsProvider(type, params);
    case "ship_station_fulfillments":
      return shipStationFulfillmentsProvider(type, params);
    case "ship_station_products":
      return shipStationProductsProvider(type, params);
    case "ship_station_tags":
      return shipStationTagsProvider(type, params);
    case "ship_station_stores":
      return shipStationStoresProvider(type, params);
    default:
      return;
  }
};

async function coinUsersProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return getCoinUsers(params);
    default:
      return;
  }
}

async function coinRefundHistoryProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return getCoinRefundHistory(params);
    default:
      return;
  }
}

async function coinRedeemHistoryProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return getCoinRedeemHistory(params);
    default:
      return;
  }
}

async function coinRedeemOptionsProvider(type, params) {
  switch (type) {
    case GET_LIST:
      return getCoinRedeemOptions(params);
    case GET_MANY:
      return getCoinRedeemOptions(params);
    default:
      return;
  }
}

async function getCoinUsers(params) {
  const { pagination, sort } = params;
  const filter = {}
  if (params.filter?.ownerUID) filter.ownerUID = { EQ: params.filter?.ownerUID }
  if (params.filter?.suspended) filter.suspended = { EQ: params.filter?.suspended }
  console.log(params)
  try {
    const result = await checkoutClient.query({
      query: UserInfoPageDocument,
      variables: {
        pagination,
        sort: { field: sort?.field, order: sort?.order === 'DESC' ? -1 : 1 },
        filter,
      }
    })
    const total = get(result, "data.userInfoPage.total") || 0;
    const data = (get(result, "data.userInfoPage.data") || []).map((user) => ({
      id: user?.ownerUID,
      ...user
    }))
    return { total, data };
  } catch (e) {
    console.log(e);
    throw e;
  }
}

const getCoinRefundHistory = async (params) => {
  const { pagination, filter: { type, uid, email, ...rest } } = params;
  const sort = {
    field: params.sort.field,
    order: params.sort.order === "DESC" ? -1 : 1,
  };
  const filter = { ...rest }
  if (uid) filter.uid = { GTE: uid };
  if (email) filter.email = { GTE: email };
  if (Array.isArray(type)) filter.type = { IN: type };
  const { data } = await checkoutClient.query({
    query: RefundRequestsDocument,
    variables: {
      filter,
      pagination,
      sort,
    },
  });
  return data.refundRequests;
};

const getCoinRedeemHistory = async (params) => {
  const {
    pagination,
    filter: { state, itemId, ...filter },
  } = params;
  const filterAdvanced = {};
  if (Array.isArray(state)) filterAdvanced.state = { IN: state };
  else if (state) filter.state = state;
  if (Array.isArray(itemId)) filterAdvanced.itemId = { IN: itemId };
  else if (itemId) filter.itemId = itemId;
  const sort = {
    field: params.sort.field,
    order: params.sort.order === "DESC" ? -1 : 1,
  };
  const variables = {
    filter,
    filterAdvanced,
    pagination,
    sort,
  };
  const { data } = await coinBossClient.query({
    query: redeemHistoryQuery,
    variables,
  });
  return data.redeemHistory;
};

const getCoinRedeemOptions = async () => {
  const { data } = await coinBossClient.query({
    query: redeemOptionsQuery,
    variables: {},
  });
  const total = (data.redeemOptions || []).length;
  return { data: data.redeemOptions, total };
};
