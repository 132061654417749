import React from 'react'
import CoinLogo from '../Logos/Coin'
import XYOLogo from '../Logos/XYO'
import LinearProgress from '@material-ui/core/LinearProgress'
import Toolbar from '@material-ui/core/Toolbar'

export default ({ children, loading }) => (
    <div className='full-size d-flex flex-column text-center'>
        {loading ? <Toolbar /> : null}
        {loading ? <LinearProgress variant='indeterminate' /> : null}
        <div className='col'></div>
        <CoinLogo className='mb-2 mx-auto' style={{ height: 100, width: 100 }} />
        <h3 className='mb-2'>WELCOME TO THE NEW ECONOMY</h3>
        <p className='mb-0'><XYOLogo /> Powered by XYO</p>
        {children}
        <div className='col'></div>
    </div>
)